import IncExc from './inc-exc'

/**
 * DocFilterChainCallback
 *
 * @callback docFilterChainCallback
 * @param {DocFilters} filter
 */

export default class DocFilters {
  #docs = new Map()

  /**
   * @private
   * @param {string} name
   */
  _ensure (name) {
    let d = this.#docs.get(name)

    if (!d) {
      this.#docs.set(name, d = new IncExc())
    }

    return d
  }

  /**
   * @param {docFilterChainCallback} a
   * @returns this
   */
  chain (a) {
    a(this)
    return this
  }

  /**
   * @returns {IncExc}
   */
  get documentTypes () { return this._ensure('document_types') }

  /**
   * @returns {IncExc}
   */
  get roles () { return this._ensure('roles') }

  toJSON () {
    return Object.fromEntries([...this.#docs.entries()].map(([k, v]) => [k, [...v]]))
  }
}
